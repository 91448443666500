nav ul {
  list-style: none;
}

nav ul {
  padding: 0;
  margin: 0;
}

nav ul {
  color: var(--pig-front);
  background-color: var(--pig-back);
  border: solid 2px var(--pig-green);
  border-radius: 5px;
}

nav ul li {
  display: inline-block;
}

nav ul li a {
  display: inline-block;
  padding: 6px 12px;
  text-decoration: none;
  color: inherit;
}

nav ul li a:hover {
  color: var(--pig-back);
  background-color: var(--pig-front);
}

nav ul li a.active {
  color: var(--pig-back);
  background-color: var(--pig-green);
}
