* {
  box-sizing: border-box;
}

html {
  --pig-green: #008359;
  --pig-light: #f3f0ad;
  --pig-dark: #27251d;
  --pig-front: var(--pig-dark);
  --pig-back: var(--pig-light);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--pig-back);
  color: var(--pig-front);
  overflow-y: scroll;
}

@media (prefers-color-scheme: dark) {
  body {
    --pig-front: var(--pig-light);
    --pig-back: var(--pig-dark);
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4 {
  font-weight: normal;
  color: var(--pig-green);
}

.container {
  margin: 0 auto;
  max-width: 500px;
  padding: 15px;
}

.logo {
  display: block;
  width: 100%;
  margin-bottom: 15px;
}

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

table > :first-child > tr:first-child > * {
  padding-top: 0;
}

table > :last-child > tr > * {
  padding-bottom: 0;
}

tr > :first-child {
  padding-left: 0;
}

tr > :last-child {
  padding-right: 0;
}

td,
th {
  vertical-align: middle;
  padding: 8px 5px;
}

thead th,
tfoot th {
  text-align: left;
}

tbody th {
  text-align: right;
  vertical-align: middle;
}

td.score {
  font-weight: lighter;
  text-align: center;
  font-size: 2em;
}

.score sup {
  font-size: 0.5em;
}

.fill {
  width: 100%;
}

.right {
  text-align: right;
}

.min-width {
  width: 0;
  white-space: nowrap;
}

input,
button {
  font-size: 1.1em;
  padding: 7px 10px;
  border-radius: 5px;
  height: 40px;
  vertical-align: top;
  border: solid 2px var(--pig-green);
}

input {
  background-color: var(--pig-light);
  color: var(--pig-dark);
}

button {
  background-color: var(--pig-green);
  border: none;
  color: var(--pig-light);
}

input {
  min-width: 40px;
}

a {
  color: #e9231a;
}

a:hover {
  cursor: url("cursor.png"), pointer;
}
