.game-card {
  margin-bottom: 1.5rem;
}

.game-card--title {
  margin: 0 0 0.5rem;
}

.game-card--meta {
  margin: 0.5rem 0;
}

.game-card--players {
  list-style: none;
  margin: 0;
  padding: 0;
}

.game-card--players li {
  display: inline;
  margin-left: 1rem;
  white-space: nowrap;
}

.game-card--players li.winner {
  font-weight: 600;
  margin-left: 0;
}
